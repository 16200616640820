<template>
    <div class="row m-0">
        <div :class="`col step border ${index < currentStepIndex ? (`bg-${variant}` || 'secondary') : 'bg-transparent'} p-0`"  v-b-tooltip.hover.bottom="{ customClass: `top-0`, boundary: 'document', variant }" :title="step.tooltipText" role="button" v-for="(step, index) in steps" :key="step.tooltipText" @click="clickHandler(step._id)">
            <div :class="`w-100 `" :style="`height: ${height || '.5em'};`"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'steps',
    props: [
        'steps', 'variant', 'currentStepIndex', 'height'
    ],
    methods: {
        clickHandler(e) {
            this.$emit('click', e)
        }
    }
}
</script>

<style>
    .step:first-child {
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
    }
    .step:not(:first-child) {
        border-left: 0 !important;
    }
    .step:last-child {
        border-top-right-radius: 1em;
        border-bottom-right-radius: 1em;
    }
</style>