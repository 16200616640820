<template>
    <div class="vstack gap-3">
        <!-- Gambiarra pras cores do gráfico -->
        <span class="d-none bg-green"></span>
        <span class="d-none bg-red"></span>
        <span class="d-none bg-purple"></span>
        <span class="d-none bg-primary"></span>
        <span class="d-none bg-pink"></span>
        <span class="d-none bg-danger"></span>
        <span class="d-none bg-warning"></span>
        <span class="d-none bg-secondary"></span>
        <span class="d-none bg-success"></span>
        <!--  -->
        <div class="hstack gap-3 bg-white rounded py-3 px-4 justify-content-between">
            <div class="hstack gap-3">
                <div>
                    <b-iconstack font-scale="5">
                        <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                        <b-icon stacked icon="kanban-fill" scale="0.5" variant="white"></b-icon>
                    </b-iconstack>
                </div>
                <div>
                    <div class="h6 m-0">
                        Relatório de Desempenho
                    </div>
                    <!-- <div class="text-secondary">
                        {{ totalLogs | decimalSpacing }} Acessos
                    </div> -->
                </div>
            </div>
            <div class="hstack gap-3">
                <b-dropdown ref="dropdownDate" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="dateFilterText">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="1">
                            <div class="ms-1 small-text">Hoje</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="2">
                            <div class="ms-1 small-text">Esta Semana</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="3">
                            <div class="ms-1 small-text">Este Mês</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="4">
                            <div class="ms-1 small-text">Período</div>
                        </b-form-radio>
                    </div>
                    <div class="vstack gap-3 p-3 pt-0" v-if="filters.typeDate">
                        <div class="vstack gap-3" v-if="filters.typeDate == 4">
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Inicial:">
                                <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                            </b-form-group>
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Final:">
                                <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                            </b-form-group>
                        </div>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-dropdown ref="dropdownType" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="filters.filter.type == 'department' ? 'Departamento' : filters.filter.type == 'operator' ? 'Operador' : 'Filtrar por'">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.filter.type" name="radio-type" value="department" @change="filters.filter.value = null">
                            <div class="ms-1 small-text">Departamento</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.filter.type" name="radio-type" value="operator" @change="filters.filter.value = null">
                            <div class="ms-1 small-text">Operador</div>
                        </b-form-radio>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.filter.type">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.filter.value" v-if="filters.filter.type == 'department'">
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.filter.value" v-else-if="filters.filter.type == 'operator'">
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownType">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-button class="rounded-pill py-0 px-4 text-white fw-semibold" variant="green" @click="getData(1)" :disabled="!filters.typeDate || !filters.filter.value">
                    Gerar
                </b-button>
            </div>
        </div>
        <div class="p-5 text-center bg-white rounded" v-if="loadingData">
            <b-spinner></b-spinner>
        </div>
        <div class="vstack gap-3" v-else-if="chartData">
            <b-row class="m-0 gap-3">
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded" style="background-color: hsl(231.67deg 37.11% 61.96%)">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(230.34deg 78.38% 78.24%)"></b-icon>
                        <b-icon stacked icon="headset" scale="0.7" style="color: hsl(231.67deg 37.11% 61.96%)" />
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Atendimentos Iniciados
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(230.34deg 78.38% 78.24%)">
                        {{ counts.totalAttendances || 0 }}
                    </div>
                </b-col>
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded" style="background-color: hsl(9.36deg 56.63% 51.18%)">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(9.83deg 100% 77.25%)"></b-icon>
                        <b-icon stacked icon="chat-right" scale="0.5" shift-v=".5" shift-h="1" style="color: hsl(9.36deg 56.63% 51.18%)"></b-icon>
                        <b-icon stacked icon="square-fill" scale="0.4" shift-v="-.5" shift-h="-.2" style="color: hsl(9.83deg 100% 77.25%)"></b-icon>
                        <b-icon stacked icon="chat-left-text" scale="0.5" shift-v="-1.25" shift-h="-1" style="color: hsl(9.36deg 56.63% 51.18%)"></b-icon>
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Atendimentos Respondidos
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(9.83deg 100% 77.25%)">
                        {{ counts.totalAttendances || 0 }}
                    </div>
                </b-col>
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded" style="background-color: hsl(86.97deg 35.46% 49.22%)">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(85.71deg 55.68% 65.49%)"></b-icon>
                        <b-icon stacked icon="check" scale="1.25" style="color: hsl(86.97deg 35.46% 49.22%)" />
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Atendimentos Finalizados
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(85.71deg 55.68% 65.49%)">
                        {{ counts.totalFinished || 0 }}
                    </div>
                </b-col>
            </b-row>
            <div class="bg-white rounded">
                <b-row class="m-0 py-3 px-4 border-bottom-grey">
                    <b-col class="fw-bold p-0">
                        Contato
                    </b-col>
                    <b-col class="fw-bold p-0" cols="2">
                        Telefone
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Kanban
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Data de Início do Atendimento
                    </b-col>
                    <b-col class="fw-bold p-0" cols="1">
                        Resposta
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Status do Atendimento
                    </b-col>
                    <b-col class="fw-bold p-0" cols="2"></b-col>
                </b-row>
                <div v-for="(data, i) of tableData" :key="data._id">
                    <b-row class="m-0 py-3 px-4 align-items-center" :class="{'border-bottom-grey': i < tableData.length - 1}">
                        <b-col class="p-0">
                            <div class="d-flex align-items-center">
                                <div class="me-2">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="data.photoURL"></b-avatar>
                                </div>
                                <div>
                                    {{ data.contactName }} 
                                </div>
                            </div>
                        </b-col>
                        <b-col class="p-0" cols="2">
                            <div>
                                {{ data.contactNumber }} 
                            </div>
                        </b-col>
                        <b-col class="p-0">
                            <div>
                                {{ data.kanban }} 
                            </div>
                        </b-col>
                        <b-col class="p-0">
                            {{ data.createdAt | date('DD/MM/YY HH:mm:ss') }}
                        </b-col>
                        <b-col class="p-0" cols="1">
                            <span v-if="data.contactFirstMessage?.timestamp">
                                {{ data.contactFirstMessage.timestamp | date('HH:mm') }}
                            </span>
                            <span v-else>
                                - - - -
                            </span>
                        </b-col>
                        <b-col class="p-0">
                            <span v-if="data.attendanceStatus">
                                {{ data.attendanceStatus }}
                            </span>
                            <span v-else>
                                - - - -
                            </span>
                        </b-col>
                        <b-col class="p-0" cols="2">
                            <div class="d-flex justify-content-end gap-3">
                                <b-button class="bg-primary border-0 rounded-circle px-2" v-b-toggle="`steps${data._id}`" v-b-tooltip.hover.bottom="{ variant: 'primary', customClass: 'top-0', boundary: 'document' }" title="Ver Etapas">
                                    <b-icon icon="chevron-down"></b-icon>
                                </b-button>
                                <b-button class="bg-green border-0 rounded-circle px-2" @click="selectContact(data, true)" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Ver Histórico">
                                    <b-icon class="mt-1" icon="chat-square-text" scale="0.9"></b-icon>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <b-collapse class="w-100 hstack gap-2 py-3 px-4 bg-grey" :id="`steps${data._id}`">
                        <div class="hstack gap-2" v-for="(step, i) of data.updateHistory" :key="step._id">
                            <span>
                                {{ step.name }}
                            </span>
                            <b-icon icon="arrow-right" v-if="i < data.updateHistory.length - 1"></b-icon>
                        </div>
                    </b-collapse>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center px-4 py-3 bg-white rounded" v-if="tableData && counts.totalAttendances > perPage">
                <b-pagination class="m-0"
                    :total-rows="counts.totalAttendances"
                    v-model="currentPage"
                    :per-page="perPage"
                    @change="pageChange"
                ></b-pagination>
                <span class="pagdescription">
                    <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{ (currentPage * perPage > counts.totalAttendances) ? counts.totalAttendances : currentPage * perPage }} de {{ counts.totalAttendances }} atendimentos</div>
                </span>
            </div>
        </div>
        <div class="p-5 text-center bg-white rounded text-secondary" v-else-if="loadedData">
            Nenhum dado encontrado!
        </div>
        <FAB :screenshotBtn="true" />
        <b-modal id="attendanceHistory" ref="attendanceHistory" title="Histórico de Atendimento" header-class="py-0" body-class="p-0" hide-footer v-if="selectedContact">
            <div class="d-flex align-items-center p-3">
                <b-avatar class="rounded-circle attendanceHistoryAvatar bg-secondary text-white" :src="selectedContact.photoURL"></b-avatar>
                <div class="ms-2">
                    <div class="fw-bold">{{ selectedContact.name }}</div>
                    <Steps
                        class="text-white mt-1 w-100"
                        variant="pink"
                        :steps="steps?.map((step, index) => ({ _id: step._id, tooltipText: `Etapa ${$options.filters.pad(index + 1, 2)} ${step.name}` }))"
                        :currentStepIndex="steps?.findIndex(el => el._id == selectedContact.stepId) + 1 || 0"
                        style="max-width: 10rem"
                    />
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between text-secondary border-top border-bottom py-2 px-3">
                <div class="d-flex align-items-center">
                    <b-icon class="me-1" icon="whatsapp"></b-icon>
                    <div>{{ selectedContact.number }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="selectedContact.state">
                    <b-icon class="me-1" icon="geo-alt"></b-icon>
                    <div>{{ parseState(selectedContact.state) }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="protocol.attStartDate"> <!-- attendance start -->
                    <div class="text-capitalize">{{ protocol.attStartDate | date('MMM DD [●] HH:mm:ss') }}</div>
                </div>
            </div>
            <div v-if="selectedContact.attendances?.length">
                <div class="py-2 pb-3 px-3">
                    <b-form-group label-class="text-purple" label="Protocolo:">
                        <b-form-select class="w-100 py-1 text-secondary" style="border-color: #ccc; border-radius: 0.3em" @change="pickProtocol">
                            <b-form-select-option :value="protocol" v-for="protocol in selectedContact.attendances" :key="protocol._id">
                                {{ protocol.protocol }} - 
                                <span class="text-capitalize">
                                    {{ (protocol.attendanceStartTime || protocol.createdAt) | date('DD/MM/YY [●] HH:mm:ss') }}
                                </span>
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="border-top" v-if="protocol._id">
                    <div class="bg-light rounded p-2 m-3">
                        <h5 class="mb-3 text-purple fw-normal">Status do Atendimento</h5>
                        <b-row>
                            <b-col>
                                <div class="fw-bold  text-purple">Início</div>
                                <span class="text-purple text-capitalize">{{ protocol.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}</span>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Fim</div>
                                <span class="text-purple text-capitalize">{{ protocol.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-purple">
                                <div class="fw-bold">Status</div>
                                <div class="text-purple" v-if="protocol.status">
                                    {{ protocol.status | translateStatus }}
                                </div>
                                <div v-else>
                                    ----
                                </div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Canal</div>
                                <div class="text-purple">{{ protocol.channelNumber || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Operador</div>
                                <div class="text-purple" v-if="!protocol.operators?.length">{{ protocol.operatorName || getOperatorName(protocol.operatorId) || '----' }}</div>
                                <div class="text-purple" v-else>
                                    <span v-for="(op, i) in protocol.operators" :key="op._id">
                                        {{ op.operatorName || getOperatorName(op.operatorId) }}
                                        <span v-if="i + 1 < protocol.operators.length">
                                            <b-icon icon="arrow-right"></b-icon>
                                        </span>
                                    </span>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Telefone</div>
                                <div class="text-purple">{{ selectedContact.number || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Contato</div>
                                <div class="text-purple">{{ selectedContact.name || '----' }}</div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Gênero</div>
                                <div class="text-purple">{{ parseGender(selectedContact.genre) || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Estado</div>
                                <div class="text-purple">{{ selectedContact.state || '----' }}</div>
                            </b-col>
                            <b-col v-if="selectedContact.email">
                                <div class="fw-bold text-purple">Email</div>
                                <div class="text-purple">{{ selectedContact.email || '----' }}</div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="bg-light rounded p-2 m-3" v-if="protocol.messages && protocol.messages.length">
                        <h5 class="mb-3 text-purple fw-normal">Chat do Atendimento</h5>
                        <Whatsapp :messages="protocol.messages" :height="'15em'" :mediaURL="mediaURL" :timestampDiff="timestampDiff" :user="user" />
                        <div class="position-absolute" style="right: 2000%">
                            <div id="printHeader" class="bg-grey-2 py-4 px-5" style="width: 62em;">
                                <b-img style="width: auto; height: 3em;" :src="user.iconURL ? user.iconURL : require(`../assets/images/logomarca/logomarca-dark.png`)"></b-img>
                                <!-- <div>
                                    <div class="small-text fw-bold">Protocolo de Impressão</div>
                                    <div class="smaller-text text-secondary">
                                        {{ protocol.protocol }}
                                    </div>
                                </div> -->
                            </div>
                            <div id="printFooter" class="bg-grey-2 py-4 px-5" style="width: 62em;">
                                <b-img style="width: auto; height: 2.5em;" :src="user.iconURL ? user.iconURL : require(`../assets/images/logomarca/logomarca-dark.png`)"></b-img>
                            </div>
                        </div>
                        <div class="d-none">
                            <div class="d-flex flex-column w-100 h-100" id="element-to-convert">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between pb-4">
                                        <div class="d-flex align-items-center position-relative">
                                            <b-avatar class="rounded-circle bg-secondary text-white" size="3.5em" :src="selectedContact.photoURL"></b-avatar>
                                            <span class="position-absolute" style="left: 3.75em; top: 0em;">
                                                <div class="rounded-pill px-2 py-1 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(selectedContact.tag)?.color}; font-size: 0.46em`" role="button" v-if="selectedContact.tag && getTagInfo(selectedContact.tag)">
                                                    <b-icon icon="circle-fill"></b-icon>
                                                    &#8205;
                                                    <div class="ms-1 text-uppercase">
                                                        {{ getTagInfo(selectedContact.tag)?.name }}
                                                    </div>
                                                </div>
                                            </span>
                                            <div class="ms-2">
                                                <div class="fw-bold">{{ selectedContact.name }}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <b-row>
                                                <b-col class="d-flex align-items-center justify-content-center fw-semibold">
                                                    <b-icon class="me-2" icon="whatsapp"></b-icon>
                                                    <div class="text-capitalize">{{ selectedContact.number || '----' }}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="smaller-text">
                                                <b-col class="d-flex align-items-center justify-content-center">
                                                    <b-icon class="me-2" icon="calendar-4"></b-icon>
                                                    <div class="text-capitalize">{{ protocol.attStartDate | date('MMM DD [●] HH:mm:ss') }}</div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                    <div class="w-100 border-top border-bottom py-3" style="font-size: 0.95em;">
                                        <span class="fw -bold">
                                            Protocolo: 
                                        </span>
                                        {{ protocol.protocol }}
                                    </div>
                                    <div class="py-4 border-bottom">
                                        <h5 class="mb-4 fw-bold">Status do Atendimento</h5>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Início: </span>
                                                <span class="text-capitalize">{{ protocol.attStartDate | date('MMM DD [●] HH:mm:ss') }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Fim: </span>
                                                <span class="text-capitalize">{{ protocol.attFinishDate | date('MMM DD [●] HH:mm:ss') }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Status: </span>
                                                <span class="text-capitalize" v-if="protocol.attStatus">
                                                    {{ protocol.attStatus | translateStatus }}
                                                </span>
                                                <div v-else>
                                                    ----
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Canal: </span>
                                                <span class="text-capitalize">{{ protocol.attChannel || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Contato: </span>
                                                <span class="text-capitalize">{{ selectedContact.name || '----' }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Telefone: </span>
                                                <span class="text-capitalize">{{ selectedContact.number || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Estado: </span>
                                                <span class="text-capitalize">{{ parseState(selectedContact.state) }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Gênero: </span>
                                                <span class="text-capitalize">{{ parseGender(selectedContact.genre) || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col v-if="selectedContact.email">
                                                <span class="fw-bold">Email: </span>
                                                <span class="text-capitalize">{{ selectedContact.email || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="mt-4 pb-2">
                                        <h5 class="mb-4 fw-bold">Chat do Atendimento</h5>
                                        <Whatsapp class="pdfPrint bg-secondary" :messages="protocol.messages" :height="'100%'" :mediaURL="mediaURL" :timestampDiff="timestampDiff" :pdfPrint="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-secondary text-center py-3" v-else>
                Nenhum atendimento encontrado!
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService'
import utils from '../utils/utils'
import FAB from './FAB.vue'
import Whatsapp from './whatsapp.vue'
import Steps from './steps.vue'

export default {
    props: [
        "user", 
        "timestampDiff",
        "mediaURL"
    ],
    components: {
        FAB,
        Whatsapp,
        Steps
    },
    mounted() {
        this.getOperators()
        this.getDepartments()
        // this.getKanbans()
        // this.getAllTags()
        this.onCloseModal()
    },
    data() {
        return {
            filters: {
                typeDate: '3',
                startDate: null,
                endDate: null,
                filter: {
                    type: null,
                    value: null
                }
            },
            operators: [],
            departments: [],
            chartData: null,
            tableData: null,
            kanbans: [],
            steps: [],
            totalCards: 0,
            loadingKanbans: false,
            loadingData: false,
            loadedData: false,
            currentPage: 1,
            perPage: 10,
            selectedContact: {},
            selectedCard: {},
            protocol: {},
            tags: [],
            counts: {},
        }
    },
    computed: {
        dateFilterText() {
            let text = ''
            switch(this.filters.typeDate) {
                case 1:
                case '1':
                    text = 'Hoje'
                    break
                case 2:
                case '2':
                    text = 'Esta Semana'
                    break
                case 3:
                case '3':
                    text = 'Este Mês'
                    break
                case 5:
                case '5':
                    text = 'Este Ano'
                    break
                default:
                    text = 'Período'
            }
            return text
        },
    },
    methods: {
        async getData(page = 1)  {
            this.loadingData = true
            this.filters.firstPeriod = this.filters.startDate
            this.filters.finalPeriod = this.filters.endDate
            const resp = await api.getReportKanbanPerformance(this.user.channelId || this.user.roleId, this.filters, page)
            console.log({ resp })

            if(resp.statusCode == 200) {
                this.counts = {
                    totalAnswered: resp.kanbanReport.totalAnswered,
                    totalAttendances: resp.kanbanReport.totalAttendances,
                    totalFinished: resp.kanbanReport.totalFinished,
                }
                if (resp.kanbanReport?.cards?.length) {
                    const steps = []
                    const kanbanSteps = []
                    const tableData = []

                    resp.kanbanReport.cards = resp.kanbanReport.cards.filter(el => !el.finished)
    
                    for (const index in resp.kanbanReport.cards) {
                        const card = resp.kanbanReport.cards[index]

                        if(card.attendances?.length) {
                            try {
                                const attendanceResp = await api.getAttendanceById(card.attendances.at(-1))
    
                                if (attendanceResp.statusCode === 200) {
                                    card.lastAttendance = attendanceResp.attendance

                                    try {
                                        card.attendanceStatus = await this.getAttendanceStatus(card.lastAttendance)
                                    } catch(error) {
                                        console.error(error)
                                    }
                                }
                            } catch(error) {
                                console.error(error)
                            }

                            try {
                                const messagesResp = await api.getProtocolMessages(card.attendances[0])

                                if (messagesResp.statusCode === 200) {
                                    card.contactFirstMessage = messagesResp.messages.messages.find(message => message.fromMe === false)
                                }
                            } catch(error) {
                                console.error(error)
                            }
                        } 
    
                        const stepIndex = steps.findIndex(step => step._id === card.stepId)
    
                        if (stepIndex === -1) {
                            try {
                                const stepResp = await api.getStepById(card.stepId)
            
                                if (stepResp.statusCode === 200) {
                                    try {
                                        const stepsResp = await api.getKanbanSteps(stepResp.step.idKanban)

                                        if (stepsResp.statusCode === 200) {
                                            kanbanSteps.push(...stepsResp.steps)
                                            for (const step of stepsResp.steps) {
                                                if (step._id !== stepResp.step._id) {
                                                    steps.push({...step, cards: []})
                                                }
                                            }
                                        }
                                    } catch (error) {
                                        console.error(error)
                                    }

                                    steps.push({
                                        ...stepResp.step,
                                        cards: [card]
                                    })

                                    let kanban = stepResp.step.name
                                    try {
                                        const kanbanResp = await api.getKanbanById(stepResp.step.idKanban)
                                        if (kanbanResp.statusCode === 200) {
                                            kanban = `${kanbanResp.kanban.name} - ${kanban}`
                                        }
                                    } catch(error) {
                                        console.error(error)
                                    }
                                    tableData.push({
                                        ...card,
                                        kanban,
                                        stepData: stepResp.step
                                    })
                                }
                            } catch(error) {
                                console.error(error)
                            }
                        } else {
                            let kanban = steps[stepIndex].name
                            try {
                                const kanbanResp = await api.getKanbanById(steps[stepIndex].idKanban)
                                if (kanbanResp.statusCode === 200) {
                                    kanban = `${kanbanResp.kanban.name} - ${kanban}`
                                }
                            } catch(error) {
                                console.error(error)
                            }
                            tableData.push({
                                ...card,
                                kanban,
                                stepData: steps[stepIndex]
                            })
                            steps[stepIndex].cards.push(card)
                        }
                    }
    
                    const data = []
                    const labels = []
                    const backgroundClassArray = ['bg-primary', 'bg-danger', 'bg-warning', 'bg-success', 'bg-purple', 'bg-green', 'bg-red', 'bg-pink', 'bg-secondary']
    
                    for (const index in steps) {
                        const step = steps[index]
                        step.backgroundClass = backgroundClassArray[index]
                        data.push(step.cards.length)
                        labels.push(`${step.name}`)
                    }
    
                    this.chartData = {
                        data,
                        labels,
                        backgroundClassArray
                    }

                    this.tableData = tableData.sort((a, b) => a.contactName.localeCompare(b.contactName))

                    this.totalCards = resp.kanbanReport.cards.length

                    steps.sort((a, b) => {
                        const indexA = kanbanSteps.findIndex(item => item._id === a._id);
                        const indexB = kanbanSteps.findIndex(item => item._id === b._id);
                        return indexA - indexB;
                    })
    
                    this.steps = steps
                } else {
                    this.chartData = null
                }
            } else {
                this.steps = []
                this.chartData = null
                this.totalData = 0
            }
            
            this.loadingData = false

            if (page != this.currentPage) {
                this.currentPage = page
            }

            if(!this.loadedData)
                this.loadedData = true
        },
        async getKanbans() {
            this.loadingKanbans = true
            const resp = await api.getKanbans(this.user.channelId || this.user.roleId, 1)
            // console.log({resp})
            if(resp.statusCode === 200) {
                this.kanbans = resp.kanbans
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao carregar kanbans!",
                    success: false
                })
            }
            this.loadingKanbans = false
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            // console.log({resp})
            if(resp.statusCode != 200) {
                this.operators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getManagers() {
            const resp = await api.getManagers(this.user.channelId || this.user.roleId)
            // console.log({resp})
            if(resp.statusCode != 200) {
                this.managers = []
                return
            }

            this.managers = resp.managers
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        selectLog(log) {
            this.selectedLog = log
        },
        roleFormat(role) {
            switch(role) {
                case "channel_manager":
                    return "Supervisor"
                case "channel":
                    return "Canal"
                case "operator":
                    return "Operador"
                case "admin":
                    return "Admin"
                default:
                    return "- - - -"
            }
        },
        estadoCompletoParaSigla(estado) {
            return utils.estadoCompletoParaSigla(estado)
        },
        pageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.getData(e)
            }
        },
        onCloseModal() {
            this.selectedLog = null
        },
        closeDropdownDate() {
            this.$refs.dropdownDate.hide(true)
        },
        closeDropdownType() {
            this.$refs.dropdownType.hide(true)
        },
        getKanbanName(kanbanId) {
            const kanban = this.kanbans.find(el => el._id == kanbanId)
            if(kanban)
                return kanban.name
            else
                return ""
        },
        formatPercentage(value, total) {
            let n = ((value / total) || 0) * 100
            if(Number.isInteger(n)) {
                return n
            }
            return n.toFixed(1)
        },
        async selectContact(card, openHistoryModal = false) {
            if (card) {
                console.log({card})
                const resp = await api.getContact(card.contactId)
                if(resp.statusCode === 200) {
                    resp.contact.stepId = card.stepId
                    this.selectedContact = resp.contact

                    const attendances = []

                    for (let i = 0; i < card.attendances.length; i++) {
                        const el = card.attendances[i]
                        try {
                            const messagesResp = await api.getProtocolMessages(el)
                            if (messagesResp.statusCode == 200) {
                                const attendanceResp = await api.getOldAttendances(this.user.channelId || this.user.roleId, {
                                    search: messagesResp.messages?.protocol
                                })
                                if (attendanceResp.statusCode == 200) {
                                    attendances.push(attendanceResp.attendances[0])
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }


                    this.$set(this.selectedContact,'attendances',attendances)

                    if (openHistoryModal && this.$refs['attendanceHistory'])
                        this.$refs['attendanceHistory'].show()
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro ao carregar históricos!",
                        success: false
                    })
                }
            }
        },
        async pickProtocol(protocol) {
            this.protocol = protocol
            const resp = await api.getProtocolMessages(protocol._id)
            // console.log('resp protocol messages',resp)
            this.$set(this.protocol,'messages',resp.messages?.messages)
        },
        parseState(state) {
            return utils.siglaPraEstadoCompleto(state) || 'N/D'
        },
        parseCountry(countryCode) {
            return utils.getCountryName(countryCode) || 'N/D'
        },
        parseGender(gender) {
            const genderMap = {
                'M': 'Masculino',
                'F': 'Feminino',
            }
            return genderMap[gender] || 'Não Identificado'
        },
        getOperatorName(operatorId) {
            let found = null
            this.operators.forEach(el => {
                if(el._id == operatorId)
                    found = el.name
            })
            return found
        },
        getTagInfo(tagId) {
            let found = null
            this.tags.forEach(el => {
                if(el._id == tagId)
                    found = el
            })
            return found
        },
        async getAllTags() {
            const resp = await api.getAllTags(this.user.channelId || this.user.roleId)
            // console.log('resp tags',resp)
            if(!resp || resp.statusCode != 200) {
                this.tags = []
            } else {
                this.tags = resp.tags
            }
        },
        selectCard(card) {
            this.selectedCard = card
        },
        formatTime(ms) {
            if (ms && ms > 1000) {
                const pad = (n) => (n < 10 ? '0' + n : n)

                const days = Math.floor(ms / (24 * 60 * 60 * 1000))
                const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
                const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000))
                const minutesms = ms % (60*1000)
                const sec = Math.floor(minutesms / 1000);

                const formattedTime = days ? `${pad(days)}:${pad(hours)}:${pad(minutes)}:${pad(sec)}` : `${pad(hours)}:${pad(minutes)}:${pad(sec)}`

                return formattedTime
            }
            return "00:00:00:00";
        },
        async getAttendanceStatus(attendance) {
            if (attendance.status === 'finished' || attendance.status === 'abandoned')
                return 'Finalizado'
            else if (attendance.status === 'waiting' || attendance.status === 'offline_operators') {
                return 'Aguardando Resposta'
            } else {
                const messagesResp = await api.getProtocolMessages(attendance._id)

                if (messagesResp.statusCode === 200) {
                    const operatorMessage = messagesResp.messages.messages.find(message => message.fromMe === true)

                    if (operatorMessage)
                        return 'Em Atendimento'
                    else
                        return 'Aguardando Resposta'
                }
            }
        },
    }
}
</script>

<style>
    .reportContacts td, .reportContacts th{
        padding: 1em 1.5em !important;
    }
</style>