<template>
  <div class="fab bg-transparent d-flex flex-column gap-3">
    <button class="fab-button bg-green opacity-75 shadow border-0 rounded-circle text-light d-flex align-items-center justify-content-center darkenOnHover" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0 opacity-75', boundary: 'document', variant: 'green' }" title="Exportar XLS" :disabled="loadingXls" @click="exportXls" v-if="xlsBtn">
        <b-icon icon="file-earmark-spreadsheet" v-if="!loadingXls"></b-icon>
        <div class="d-flex justify-content-center" v-else>
          <b-spinner style="width: 1.5rem; height: 1.5rem;" ></b-spinner>
        </div>
    </button>
    <button class="fab-button bg-purple opacity-75 shadow border-0 rounded-circle text-light d-flex align-items-center justify-content-center darkenOnHover" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0 opacity-75', boundary: 'document', variant: 'purple' }" title="Printar Tela" @click="screenshot" v-if="screenshotBtn">
        <b-icon icon="camera"></b-icon>
    </button>
  </div>
</template>
  
<script>
  import html2canvas from 'html2canvas';
  
  export default {
    props: [
      'screenshotBtn', 'xlsBtn'
    ],
    mounted() {
      this.$nextTick(() => {
        this.onEvents()
      })
    },
    data() {
      return {
        loadingXls: false
      }
    },
    methods: {
        screenshot() {
          const screenshotTarget = document.querySelector(`#content`);

          // Calculate the total height of the element, including the hidden part
          const scrollHeight = screenshotTarget.scrollHeight;

          // Set the height of the screenshotTarget to the total height
          screenshotTarget.style.height = scrollHeight + 'px';

          if(screenshotTarget) {
              html2canvas(screenshotTarget, {
                  ignoreElements: element => 
                      element.classList.contains('appHeader') ||
                      element.classList.contains('fab')
              }).then(canvas => {
                  screenshotTarget.style.height = '100dvh';
                  // Convert the canvas to Blob
                  canvas.toBlob(function(blob) {
                      // To download directly on the browser's default 'downloads' location
                      const link = document.createElement("a");
                      link.download = "imagem.png";
                      link.href = URL.createObjectURL(blob);
                      link.click();
                  }, 'image/png');
              });
          }
        },
        exportXls() {
          this.loadingXls = true
          this.$emit('exportXlsData')
        },
        onEvents() {
          this.$on('exportedXls', () => {
            this.loadingXls = false
          })
        }
    }
  }
</script>
  
<style>
  .fab {
    position: fixed;
    bottom: 6rem;
    right: 1.5rem;
    font-size: 24px;
  }
  .fab-button {
    width: 2.5rem;
    height: 2.5rem;
  }
</style>
  